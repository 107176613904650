// Constant Action Types for Night Life Booking Widget

export const REVERT_STAGE = 'REVERT_STAGE'
export const STEP_FORWARD_MONTH = 'STEP_FORWARD_MONTH'
export const STEP_BACK_MONTH = 'STEP_BACK_MONTH'
export const SCROLL_BODY = 'SCROLL_BODY'
export const TOP_BODY = 'TOP_BODY'
export const SELECT_EVENT = 'SELECT_EVENT'
export const TRY_GET_EVENTS = 'TRY_GET_EVENTS'
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS'
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE'
export const RESIZE_BODY = 'RESIZE_BODY'
export const INCREMENT_ITEM = 'INCREMENT_ITEM'
export const DECREMENT_ITEM = 'DECREMENT_ITEM'
export const TOGGLE_INVENTORY_ITEM = 'TOGGLE_INVENTORY_ITEM'
export const SELECT_INVENTORY_ITEM = 'SELECT_INVENTORY_ITEM'
export const SET_LOGIN_INFO = 'SET_LOGIN_INFO'
export const SET_USER_DATA_FB = 'SET_USER_DATA_FB'
export const SET_USER_DATA_GP = 'SET_USER_DATA_GP'
export const SAVE_TAG_GROUP = 'SAVE_TAG_GROUP'
export const DISMISS_MODAL = 'DISMISS_MODAL'
export const DISPLAY_MODAL = 'DISPLAY_MODAL'
export const TOGGLE_BOOKING_AGREEMENT = 'TOGGLE_BOOKING_AGREEMENT'
export const TOGGLE_VENUE_GROUP_MARKETING_OPT_IN = 'TOGGLE_VENUE_GROUP_MARKETING_OPT_IN'
export const TOGGLE_VENUE_SPECIFIC_MARKETING_OPT_IN = 'TOGGLE_VENUE_SPECIFIC_MARKETING_OPT_IN'
export const TOGGLE_VENUE_SMS_MARKETING_OPT_IN = 'TOGGLE_VENUE_SMS_MARKETING_OPT_IN'
export const TOGGLE_RESERVATION_SMS_OPT_IN = 'TOGGLE_RESERVATION_SMS_OPT_IN'
export const TOGGLE_ABOVE_AGE_CONSENT = 'TOGGLE_ABOVE_AGE_CONSENT'
export const TOGGLE_SPECIAL_ATTENTION_MESSAGE = 'TOGGLE_SPECIAL_ATTENTION_MESSAGE'
export const TOGGLE_VENUE_GROUP_MARKETING_OPT_IN_DISPLAY = 'TOGGLE_VENUE_GROUP_MARKETING_OPT_IN_DISPLAY'
export const TOGGLE_VENUE_SPECIFIC_MARKETING_OPT_IN_DISPLAY = 'TOGGLE_VENUE_SPECIFIC_MARKETING_OPT_IN_DISPLAY'
export const TOGGLE_VENUE_SMS_MARKETING_OPT_IN_DISPLAY = 'TOGGLE_VENUE_SMS_MARKETING_OPT_IN_DISPLAY'
export const TOGGLE_RESERVATION_SMS_OPT_IN_POLICY_DISPLAY = 'TOGGLE_RESERVATION_SMS_OPT_IN_POLICY_DISPLAY'
export const TOGGLE_PROMO_CODE_ERROR_DISPLAY = 'TOGGLE_PROMO_CODE_ERROR_DISPLAY'
export const UPDATE_TIP_AMOUNT = 'UPDATE_TIP_AMOUNT'
export const CHANGE_PHONE_NUM = 'CHANGE_PHONE_NUM'
export const CHANGE_COUNTRY_FLAG = 'CHANGE_COUNTRY_FLAG'
export const INVALIDATE_FIELD = 'INVALIDATE_FIELD'
export const POST_CHECKOUT_SUCCESS = 'POST_CHECKOUT_SUCCESS'
export const TOGGLE_BOOKING_POLICY_DISPLAY = 'TOGGLE_BOOKING_POLICY_DISPLAY'
export const BEGIN_SCROLL_ANIMATION = 'BEGIN_SCROLL_ANIMATION'
export const END_SCROLL_ANIMATION = 'END_SCROLL_ANIMATION'
export const VALIDATE_ALL_FIELDS = 'VALIDATE_ALL_FIELDS'
export const TRY_POST_CHECKOUT = 'TRY_POST_CHECKOUT'
export const POST_CHECKOUT_FAILURE = 'POST_CHECKOUT_FAILURE'
export const POST_CHECKOUT_RELOAD_ON_FAILURE = 'POST_CHECKOUT_RELOAD_ON_FAILURE'
export const TOGGLE_CHECKOUT_ERROR_MODAL = 'TOGGLE_CHECKOUT_ERROR_MODAL'
export const TOGGLE_IMAGE_GALLERY = 'TOGGLE_IMAGE_GALLERY'
export const STEP_FORWARD_IMAGE = 'STEP_FORWARD_IMAGE'
export const STEP_BACK_IMAGE = 'STEP_BACK_IMAGE'
export const DISMISS_SPINNER = 'DISMISS_SPINNER'
export const HOVER_DATE = 'HOVER_DATE'
export const HIGHLIGHT_DATE = 'HIGHLIGHT_DATE'
export const UNHIGHLIGHT_DATE = 'UNHIGHLIGHT_DATE'
export const CLEAR_ERROR_SCROLLING = 'CLEAR_ERROR_SCROLLING'
export const CHANGE_FORM_FIELD = 'CHANGE_FORM_FIELD'
export const SKIP_LOGIN = 'SKIP_LOGIN'
export const CHANGE_CAPTCHA_FIELD = 'CHANGE_CAPTCHA_FIELD'
export const VALIDATE_CAPTCHA_FIELD = 'VALIDATE_CAPTCHA_FIELD'
export const MANDATE_POLICIES = 'MANDATE_POLICIES'
export const PASS_STRIPE_CARD_ELEMENT = 'PASS_STRIPE_CARD_ELEMENT'
export const CHANGE_BLUR = 'CHANGE_BLUR'
export const CHANGE_FOCUS = 'CHANGE_FOCUS'
export const ADD_PROMO_CODE = 'ADD_PROMO_CODE'
export const REMOVE_PROMO_CODE = 'REMOVE_PROMO_CODE'
export const GET_PROMO_CODES_SUCCESS = 'GET_PROMO_CODES_SUCCESS'
export const GET_PROMO_CODES_FAILURE = 'GET_PROMO_CODES_FAILURE'
export const INVALIDATE_PROMO_CODE_ATTEMPT = 'INVALIDATE_PROMO_CODE_ATTEMPT'
export const TRY_GET_PROMO_CODES = 'TRY_GET_PROMO_CODES'
export const TRY_VERIFY_PROMO_CODE = 'TRY_VERIFY_PROMO_CODE'
export const VERIFY_PROMO_CODE_SUCCESS = 'VERIFY_PROMO_CODE_SUCCESS'
export const VERIFY_PROMO_CODE_FAILURE = 'VERIFY_PROMO_CODE_FAILURE'
export const SET_NETWORK_REDUX_RESPONSE = 'SET_NETWORK_REDUX_RESPONSE'
export const SET_FREEDOMPAY_INITIAL_DATA = 'SET_FREEDOMPAY_INITIAL_DATA'
export const SET_FREEDOMPAY_VISIBLE = 'SET_FREEDOMPAY_VISIBLE'
export const SET_FREEDOMPAY_IFRAME_INITIALIZED = 'SET_FREEDOMPAY_IFRAME_INITIALIZED'
