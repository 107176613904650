/* eslint react/prop-types: 0, react/jsx-no-bind: 0, jsx-a11y/no-static-element-interactions: 0 */
import priceFormatter from 'currency-formatter'
import _ from 'lodash'
import React from 'react'
import { AccessibleDivComponent } from 'svr/common/A11y'
import * as styles from '../assets/styles/eventOverview'
import QuantityStepper from '../containers/QuantityStepper'
import { connect } from 'react-redux'
import { calcTotalFeePrice } from '../utils/calcCharges'

priceFormatter.currencies[0].symbol = 'AED'
priceFormatter.currencies[0].symbolOnLeft = true

const InventoryItemLarge = ({
  inventoryItem,
  inventoryEdits,
  selectInventoryItem,
  currencyCode,
  textMinimumSpend,
  isFeesInPriceDisplayed,
  defaultServiceCharge,
}) => {
  const { price, compText, inventoryId, id, inventoryType, entryPerReservation } = inventoryItem
  const maxQuantity = Math.min(inventoryItem.maxQuantity, inventoryEdits[inventoryId].remainingQuantity)
  const quantity = Math.max(inventoryItem.minQuantity, inventoryEdits[inventoryId].quantity)
  const basePrice = price * quantity

  const totalFeePrice = calcTotalFeePrice(inventoryItem, quantity, price, defaultServiceCharge)
  const totalPrice = isFeesInPriceDisplayed ? basePrice + totalFeePrice : basePrice

  let headerText
  let quantStepper
  const numGuests = quantity * entryPerReservation
  if (maxQuantity === 1 || inventoryType === 'REQUEST') {
    quantStepper = <div style={styles.quantStepper} />
  } else {
    quantStepper = (
      <QuantityStepper inventoryId={inventoryId} quantity={quantity} minQuantity={inventoryItem.minQuantity} maxQuantity={maxQuantity} />
    )
  }

  const minDollarText = (function () {
    if (!inventoryItem.minPrice) {
      return ''
    }
    const minDollarAmount = quantity * inventoryItem.minPrice
    const minDollarAmountFormatted = priceFormatter.format(minDollarAmount, {
      code: currencyCode,
    })
    return `, ${minDollarAmountFormatted} ${textMinimumSpend}`
  })()

  if (numGuests === 1) {
    const headerInner = inventoryType === 'RESERVATION' ? `For 1 guest${minDollarText}` : 'For 1 guest'
    headerText = <span>{headerInner}</span>
  } else if (inventoryType === 'REQUEST') {
    headerText = <span />
  } else {
    let headerString
    if (inventoryType === 'RESERVATION') {
      headerString = `Up to ${numGuests} guests${minDollarText}`
    } else {
      headerString = `For ${numGuests} guests`
    }
    headerText = <span>{headerString}</span>
  }

  let priceText = priceFormatter.format(totalPrice, { code: currencyCode })
  if (inventoryType === 'GUESTLIST_TICKET_FREE') {
    priceText = compText
  } else if (inventoryType === 'REQUEST') {
    priceText = 'Request'
  }

  let labelTextStyle = _.assign({}, styles.labelText)
  if (inventoryType === 'REQUEST') {
    labelTextStyle = _.assign(labelTextStyle, {
      maxHeight: '25px',
      marginTop: '-9px',
    })
  }

  return (
    <div style={_.assign({}, styles.inventoryListItem, styles.largeDevicePadding)}>
      <div style={styles.headerText} data-test="header-text">
        {headerText}
      </div>
      <div style={labelTextStyle}>{inventoryItem.inventoryName}</div>
      <div style={styles.priceHolder}>
        {quantStepper}
        <span style={styles.priceText} data-test="price-text">
          {priceText}
        </span>
        {!!(isFeesInPriceDisplayed && totalFeePrice) && (
          <span data-test="fees-in-price" style={styles.feesText}>
            * Includes {priceFormatter.format(totalFeePrice, { code: currencyCode })} in fees
          </span>
        )}
      </div>
      <AccessibleDivComponent
        aria-label="select event"
        style={styles.continueButton}
        onClick={selectInventoryItem.bind(null, inventoryId, id)}
      >
        continue
      </AccessibleDivComponent>
    </div>
  )
}
const mapStateToProps = state => ({
  textMinimumSpend: state.widgetSettings.textMinimumSpend,
})
export default connect(mapStateToProps)(InventoryItemLarge)
