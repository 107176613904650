/* eslint react/prop-types: 0, jsx-a11y/no-static-element-interactions: 0 */
import _ from 'lodash'
import React from 'react'
import { accessibleComponentWrapper } from 'svr/common/A11y'
import * as styles from '../assets/styles/eventOverview'

// eslint-disable-next-line react/button-has-type
const ButtonComponent = props => <button {...props} />
const ButtonComponentWithFocusContainer = accessibleComponentWrapper(ButtonComponent)

const Stepper = ({ inventory, quantity, maxQuantity, minQuantity, inventoryId, boundIncrementItem, boundDecrementItem, styleObj }) => {
  let minusClickHandler
  let minusStepBox
  let plusClickHandler
  let plusStepBox
  if (quantity === minQuantity) {
    minusClickHandler = e => {
      e.stopPropagation()
    }
    minusStepBox = _.assign({}, styles.stepBox, {
      opacity: '0.3',
      cursor: 'default',
    })
  } else {
    minusClickHandler = e => {
      e.stopPropagation()
      boundDecrementItem(inventoryId)
    }
    minusStepBox = _.assign({}, styles.stepBox, { cursor: 'pointer' })
  }

  if (quantity === maxQuantity) {
    plusClickHandler = e => {
      e.stopPropagation()
    }
    plusStepBox = _.assign({}, styles.stepBox, {
      opacity: '0.3',
      cursor: 'default',
    })
  } else {
    plusClickHandler = e => {
      e.stopPropagation()
      boundIncrementItem(inventoryId)
    }
    plusStepBox = _.assign({}, styles.stepBox, { cursor: 'pointer' })
  }

  const quantStyle = styleObj ? _.assign({}, styles.quantStepper, styleObj) : styles.quantStepper

  const inventoryItemName = inventory[inventoryId].inventoryName
  return (
    <div style={quantStyle}>
      <ButtonComponentWithFocusContainer
        style={minusStepBox}
        onClick={minusClickHandler}
        focusOpacity={minusStepBox.opacity}
        aria-label="decrement"
      >
        <span style={styles.minus}>-</span>
      </ButtonComponentWithFocusContainer>
      <div style={styles.stepNum}>
        <span role="alert" style={styles.quantity} aria-label={`${inventoryItemName}, quantity`} data-test="stepper-quantity">
          {quantity}
        </span>
      </div>
      <ButtonComponentWithFocusContainer style={plusStepBox} onClick={plusClickHandler} aria-label="increment">
        <span style={styles.plus}>+</span>
      </ButtonComponentWithFocusContainer>
    </div>
  )
}

export default Stepper
