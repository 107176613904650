import { TRY_GET_EVENTS, GET_EVENTS_SUCCESS, GET_EVENTS_FAILURE } from '../actions/ActionTypes'

const isFetchingEvents = (state = false, action) => {
  switch (action.type) {
    case TRY_GET_EVENTS:
      return true
    case GET_EVENTS_SUCCESS:
      return false
    case GET_EVENTS_FAILURE:
      return false
    default:
      return state
  }
}

export default isFetchingEvents
