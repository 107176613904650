import { Map } from 'immutable'
import { GET_EVENTS_SUCCESS, INCREMENT_ITEM, DECREMENT_ITEM, TOGGLE_INVENTORY_ITEM } from '../actions/ActionTypes'

const availability = (state = Map(), action) => {
  switch (action.type) {
    case GET_EVENTS_SUCCESS:
      return state.mergeDeep(action.data.availability)
    case INCREMENT_ITEM:
      return state.updateIn([action.date, action.eventId, action.inventoryId], item => item.update('quantity', quant => quant + 1))
    case DECREMENT_ITEM:
      return state.updateIn([action.date, action.eventId, action.inventoryId], item => item.update('quantity', quant => quant - 1))
    case TOGGLE_INVENTORY_ITEM:
      return state.updateIn([action.date, action.eventId], inventoryList =>
        inventoryList.map((inventoryEdit, inventoryId) => {
          if (inventoryId === action.inventoryId) {
            return inventoryEdit.update('isHighlighted', isHighlighted => inventoryList.size === 1 || !isHighlighted)
          }
          return inventoryEdit.set('isHighlighted', false)
        })
      )
    default:
      return state
  }
}

export default availability
