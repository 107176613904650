import React, { Component } from 'react'
import { connect } from 'react-redux'
import { revertStage } from '../actions/navigation'
import { fetchEvents } from '../actions/services'
import * as styles from '../assets/styles/eventList'
import Main from '../components/Main'
import Analytics from '../services/AnalyticsServices'

class App extends Component {
  componentWillMount() {
    if (!widgetInit.successBypass) {
      this.props.fetchEvents()
    }
  }

  componentDidMount() {
    Analytics.pageView(this.props.venueName)
  }

  render() {
    const spinnerUrl = `${this.props.mediaUrl}images/widget/sr_spinner.gif`
    return this.props.isFetchingEvents ? (
      <div style={styles.loadingBackground}>
        <img alt="sevenrooms-spinner" style={styles.loadingSpinner} src={spinnerUrl} />
      </div>
    ) : (
      <Main
        currentStage={this.props.currentStage}
        activeStage={this.props.activeStage}
        revertStage={this.props.revertStage}
        isLoggedIn={this.props.isLoggedIn}
        isFetchingEvents={this.props.isFetchingEvents}
        widgetSettings={this.props.widgetSettings}
        queryObj={this.props.queryObj}
      />
    )
  }
}

const mapStateToProps = state => ({
  currentStage: state.stage.get('current'),
  activeStage: state.stage.get('active'),
  isLoggedIn: state.user.get('status') === 'connected' || state.stage.get('isGuest'),
  isFetchingEvents: state.isFetchingEvents && !widgetInit.successBypass,
  mediaUrl: state.widgetSettings.mediaUrl,
  widgetSettings: state.widgetSettings,
  queryObj: state.queryObj,
  venueName: state.venueInfo.name,
})

const mapDispatchToProps = dispatch => ({
  fetchEvents: () => {
    dispatch(fetchEvents())
  },
  revertStage: index => {
    dispatch(revertStage(index))
  },
})

App = connect(mapStateToProps, mapDispatchToProps)(App)

export default App
