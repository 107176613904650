const {
  floorplanAvailableColor,
  floorplanAvailableOpacity,
  floorplanUnavailableColor,
  floorplanUnavailableOpacity,
  floorplanClickColor,
  floorplanClickOpacity,
  floorplanHoverColor,
  floorplanHoverOpacity,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} = widgetInit.settings

export const STYLES = {
  available: `fill: ${floorplanAvailableColor}; opacity: ${floorplanAvailableOpacity}`,
  click: `fill: ${floorplanClickColor}; opacity: ${floorplanClickOpacity}`,
  hover: `fill: ${floorplanHoverColor}; opacity: ${floorplanHoverOpacity}`,
  unavailable: `fill: ${floorplanUnavailableColor}; opacity: ${floorplanUnavailableOpacity}`,
}
