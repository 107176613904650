/* eslint-disable camelcase */
/* global widgetInit */
import { hexToRgb } from '../../utils/color'

const fontsColorPrimary = widgetInit.settings.fonts_color_primary
const {
  color_primary,
  color_widget_background,
  color_widget_background_opacity,
  color_cell_background,
  color_cell_background_opacity,
  color_lines,
  color_lines_opacity,
  fonts_color_button,
  fonts_color_secondary,
  fonts_color_links,
} = widgetInit.settings
const rgbaWidgetBackground = hexToRgb(color_widget_background, color_widget_background_opacity)
const rgbaCellBackground = hexToRgb(color_cell_background, color_cell_background_opacity)
const rgbaLineBackground = hexToRgb(color_lines, color_lines_opacity)

export const eventOverviewWrapper = {
  height: '100%',
  width: '100%',
}

export const viewWrapper = {
  paddingTop: '62px',
  fontFamily: 'inherit',
  outline: 'none',
  backgroundColor: rgbaWidgetBackground,
}

export const imageWrapper = {
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  cursor: 'pointer',
}

export const imageContent = {
  width: '100%',
  display: 'block',
}

export const overviewTextBox = {
  position: 'absolute',
  bottom: '18px',
  left: '5.42857%',
  color: 'white',
  paddingRight: '5px',
}

export const label = {
  fontWeight: '500',
  fontSize: '18px',
}

export const datetime = {
  fontWeight: '300',
  fontSize: '12px',
}

export const inventoryWrapper = {
  width: '100%',
  fontFamily: 'inherit',
}

export const inventoryListItem = {
  width: '100%',
  minHeight: '70px',
  backgroundColor: rgbaCellBackground,
  overflow: 'auto',
}

export const getSmallInventoryListItem = isHovered => ({
  width: '100%',
  backgroundColor: isHovered ? 'lightgray' : rgbaCellBackground,
  overflow: 'auto',
})

export const largeDevicePadding = {
  padding: '12px 1.5% 13px 5.44285%',
  cursor: 'pointer',
}

export const headerText = {
  height: '16px',
  fontWeight: '300',
  fontSize: '12px',
  color: fonts_color_secondary,
  margin: '1px 0 4px',
  float: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '506px',
}

export const headerTextSmall = {
  ...headerText,
  maxWidth: '300px',
}

export const labelText = {
  fontSize: '18px',
  fontWeight: '500',
  color: fontsColorPrimary,
  clear: 'left',
  float: 'left',
  width: '44%',
  overflow: 'hidden',
  marginBottom: '1px',
}

export const quantStepper = {
  display: 'inline-block',
  width: '96px',
  float: 'left',
  height: '46px',
  position: 'relative',
}

export const clickBox = {
  height: '32px',
  width: '32px',
  position: 'absolute',
  margin: '7px 0',
  zIndex: '1',
}

export const stepBox = {
  height: '30px',
  width: '30px',
  borderRadius: '4px',
  border: `solid 1px ${fontsColorPrimary}`,
  display: 'block',
  margin: '8px 0px',
  padding: '0px 9px',
  float: 'left',
  color: fontsColorPrimary,
  WebkitTouchCallout: 'none',
  WebkitUserSelect: 'none',
  KhtmlUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  UserSelect: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',
}

export const minus = {
  position: 'absolute',
  fontSize: '38px',
  top: '5px',
  height: '30px',
  lineHeight: '30px',
  display: 'block',
}

export const plus = {
  position: 'absolute',
  fontSize: '26px',
  top: '7px',
  height: '30px',
  lineHeight: '30px',
  display: 'block',
}

export const stepNum = {
  display: 'block',
  height: '30px',
  width: '30px',
  margin: '8px 3px',
  float: 'left',
  textAlign: 'center',
}

export const quantity = {
  fontWeight: '400',
  fontSize: '14px',
  letterSpacing: '1px',
  color: fontsColorPrimary,
  height: '30px',
  lineHeight: '30px',
  display: 'block',
  marginTop: '2px',
}

export const priceBoxSmall = {
  display: 'inline-block',
  width: '26%',
  float: 'right',
  textAlign: 'right',
  margin: '-18px 0 0 1.83333%',
}

export const priceText = {
  display: 'block',
  fontSize: '16px',
  fontWeight: '500',
  float: 'right',
  lineHeight: '36px',
  marginTop: '5px',
  marginRight: '8%',
  color: fontsColorPrimary,
}

export const feesText = {
  fontSize: '12px',
  fontWeight: '300',
  float: 'right ',
  clear: 'left',
  color: fonts_color_secondary,
  marginRight: '8%',
}

export const continueButton = {
  marginTop: '-17px',
  textAlign: 'center',
  width: '20%',
  height: '40px',
  borderRadius: '2px',
  backgroundColor: color_primary,
  fontWeight: '300',
  fontSize: '16px',
  letterSpacing: '1px',
  color: fonts_color_button,
  float: 'right',
  paddingTop: '0',
  cursor: 'pointer',
  WebkitTouchCallout: 'none' /* iOS Safari */,
  WebkitUserSelect: 'none' /* Chrome/Safari/Opera */,
  KhtmlUserSelect: 'none' /* Konqueror */,
  MozUserSelect: 'none' /* Firefox */,
  MsUserSelect: 'none' /* Internet Explorer/Edge */,
  UserSelect: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
}

export const getHighlightedListItem = isFloorplan => ({
  width: '100%',
  backgroundColor: isFloorplan ? 'lightgray' : rgbaCellBackground,
  padding: '12px 3.2% 12px 5.86666%',
  cursor: 'pointer',
  overflow: 'auto',
})

export const eventDetailsWrapper = {
  width: '100%',
  padding: '15px 6.13333% 27px 6.13333%',
  display: 'block',
  fontFamily: 'inherit',
  height: '100%',
}

export const bigLeftWrapper = {
  fontSize: '12px',
  lineHeight: '1.5',
  color: fonts_color_secondary,
  display: 'inline-block',
  float: 'left',
  maxWidth: '315px',
  width: '52%',
  paddingRight: '6%',
}

export const bigRightWrapper = {
  fontWeight: '300',
  fontSize: '12px',
  lineHeight: '1.5',
  color: fonts_color_secondary,
  display: 'inline-block',
  float: 'left',
  maxWidth: '315px',
  width: '48%',
}

export const smallWrapper = {
  opacity: '0.7',
  fontSize: '12px',
  lineHeight: '1.5',
  color: fontsColorPrimary,
  display: 'inline-block',
  float: 'left',
  maxWidth: '100%',
}

export const googleMapWrapper = {
  width: '100%',
  height: '240px',
  frameborder: '0',
  border: '0',
}

export const addressBox = {
  listStyleType: 'none',
  padding: '0px',
}

export const aTag = {
  textDecoration: 'none',
  color: fonts_color_links,
}

export const venueDetailsWrapper = {
  marginTop: '12px',
}

export const galleryCover = {
  position: 'fixed',
  top: '0',
  left: '0',
  right: '0',
  background: 'rgba(0, 0, 0, 0.8)',
  bottom: '0',
  zIndex: '101',
  margin: '0 auto',
  maxWidth: '700px',
  overflowY: 'scroll',
}

export const largeImageWrapper = {
  marginTop: '10px',
  position: 'relative',
  height: '0',
  paddingBottom: '100%',
}

export const largeImageBody = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  padding: '0 4.53333%',
}

export const largeImageContent = {
  display: 'block',
  margin: '0 auto',
  width: '100%',
}

export const imgLabel = {
  color: 'white',
  fontSize: '16px',
  padding: 10,
  textAlign: 'center',
}

export const imageStepper = {
  display: 'block',
  margin: '10px auto 0',
  width: '89.33333%',
  height: '39px',
  textAlign: 'center',
  padding: '10px 0',
}

export const imgStepperLeft = {
  float: 'left',
  height: '100%',
  padding: '0 3.2%',
  fontSize: '23px',
  color: 'white',
  marginTop: '-3px',
  cursor: 'pointer',
}

export const imgStepperRight = {
  float: 'right',
  height: '100%',
  padding: '0 3.2%',
  fontSize: '23px',
  color: 'white',
  marginTop: '-3px',
  cursor: 'pointer',
}

export const stepDisplay = {
  fontSize: '15px',
  fontWeight: '500',
  color: 'white',
}

export const imageX = {
  position: 'absolute',
  color: 'white',
  fontSize: '30px',
  top: '0%',
  right: '6%',
  fontWeight: '100',
  textShadow: '0px 0px 2px rgba(70, 70, 70, 1)',
  cursor: 'pointer',
}

export const inventoryItemSeperator = {
  border: `solid 1px ${rgbaLineBackground}`,
  margin: '0',
}

export const noAvailText = {
  fontFamily: 'inherit',
  fontSize: '15px',
  lineHeight: '45px',
  fontStyle: 'italic',
  color: fonts_color_secondary,
}

const start = hexToRgb('#000000', 0)
const finis = hexToRgb('#000000', 0.7)

export const gradient = {
  background: `linear-gradient(${start}, ${finis})`,
  bottom: 0,
  height: '100%',
  position: 'absolute',
  width: '100%',
}

export const galleryIcon = {
  right: '10px',
  color: 'white',
  position: 'absolute',
  top: '10px',
  zIndex: '5',
  height: '28px',
}

export const tableRowHeight = {
  height: '0px',
}

export const hiddenAlert = {
  position: 'absolute',
  top: '-9999px',
  left: '-9999px',
}

export const continueButtonWrapper = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
}

export const priceHolder = {
  float: 'left',
  marginLeft: '2%',
  marginTop: ' -12px',
  width: '34%',
}
