/* eslint camelcase: 0 */
/* global widgetInit */
import { hexToRgb } from '../../utils/color'

const fontsColorPrimary = widgetInit.settings.fonts_color_primary
const fontsColorSecondary = widgetInit.settings.fonts_color_secondary
const fontsColorError = widgetInit.settings.fonts_color_error
const {
  color_background,
  color_checkout_background,
  color_checkout_background_opacity,
  color_primary,
  fonts_color_button,
  fonts_color_checkout_active,
  fonts_color_checkout_inactive,
  fonts_color_links,
  color_widget_background,
  color_widget_background_opacity,
} = widgetInit.settings
const rgbaBackground = hexToRgb(color_background, 1)
const rgbaCheckoutBackground = hexToRgb(color_checkout_background, color_checkout_background_opacity)
const rgbaWidgetBackground = hexToRgb(color_widget_background, color_widget_background_opacity)

export const checkoutWrapper = {
  paddingTop: '64px',
  width: '100%',
  height: '100%',
  fontFamily: 'inherit',
  backgroundColor: rgbaWidgetBackground,
}

export const banner = {
  height: '30px',
  backgroundColor: fontsColorPrimary,
  textAlign: 'center',
  paddingTop: '6px',
}

export const bannerText = {
  opacity: '0.9',
  fontFamily: 'inherit',
  fontSize: '12px',
  fontWeight: '400',
  color: '#ffffff',
}

export const formWrapper = {
  width: '100%',
  paddingBottom: '13px',
}

export const sectionWrapper = {
  width: '89.33333%',
  margin: '0 auto',
  paddingTop: '10px',
}

export const summaryWrapper = {
  width: '89.33333%',
  margin: '0 auto',
  paddingTop: '20px',
}

export const labelWrapper = {
  textAlign: 'center',
  width: '137px',
  height: '19px',
  margin: '0px auto 10px',
  paddingTop: '1px',
}

export const sectionLabel = {
  fontSize: '14px',
  display: 'block',
  color: fontsColorPrimary,
}

export const infoForm = {
  borderRadius: '8px',
  backgroundColor: rgbaCheckoutBackground,
}

export const formLine = {
  width: '100%',
  height: '46px',
  display: 'table',
}

export const autoFormLine = {
  height: '46px',
  display: 'table',
}

export const smallFormLine = {
  width: '100%',
  height: '30px',
  display: 'table',
}

export const formBox = {
  height: '100%',
  display: 'table-cell',
  verticalAlign: 'middle',
  width: '50%',
}

export const defaultFormBox = {
  height: '100%',
  display: 'table-cell',
  width: '50%',
}

export const formLineSeperator = {
  borderStyle: 'solid',
  borderWidth: '1px',
  margin: '0',
  width: '100%',
}

export const floatingLabel = {
  fontSize: '10px',
}

export const firstName = {
  width: '45.67164%',
  height: '100%',
  display: 'inline-block',
  float: 'left',
  borderRadius: '0 8px 0 0',
}

export const lastName = {
  width: '54.6513%',
  maxWidth: '340px',
  height: '100%',
  display: 'inline-block',
  float: 'right',
  marginLeft: '-2px',
  borderRadius: '0 8px 0 0',
}

export const firstNameText = {
  fontSize: '14px',
  lineHeight: '1.5',
  color: fonts_color_checkout_active,
  display: 'block',
}

export const lastNameText = {
  fontSize: '14px',
  lineHeight: '1.5',
  color: fonts_color_checkout_active,
  display: 'block',
  oveflow: 'auto',
}

export const emailText = {
  fontSize: '14px',
  lineHeight: '1.5',
  color: fonts_color_checkout_active,
  display: 'block',
  height: '100%',
  width: '100%',
  padding: '12px 4% 13px 4.47761%',
  oveflow: 'auto',
}

export const activePhoneText = {
  color: fonts_color_checkout_active,
  backgroundColor: 'transparent',
  display: 'block',
}

export const inactivePhoneText = {
  color: fonts_color_checkout_inactive,
  backgroundColor: 'transparent',
  display: 'block',
}

export const errorPhoneText = {
  color: fontsColorError,
  backgroundColor: 'transparent',
  display: 'block',
}

export const arrowColor = {
  color: fonts_color_checkout_active,
}

export const lineText = {
  display: 'block',
  padding: '11px 3.69880% 12px 4.17910%',
  border: '2px solid transparent',
  zIndex: '2',
  height: '56px',
}

export const inputField = {
  border: 'none',
  height: '24px',
  fontSize: '14px',
  lineHeight: '19px',
  display: 'block',
  color: fonts_color_checkout_active,
  outline: 'none',
  padding: '0',
  width: '100%',
  fontFamily: 'inherit',
  backgroundColor: 'transparent',
}

export const readOnlyInputText = {
  fontStyle: 'italic',
  pointerEvents: 'none',
  userSelect: 'none',
}

export const stripePaymentElement = {
  padding: '2.5% 2.5% 2.5% 2.5%',
  borderRadius: '8px 8px 8px 8px',
}

export const stripeIframe = {
  fontFamily: 'Avenir, Roboto, sans-serif',
  fontSizeBase: '14px',
  fontSize: '15px',
  lineHeight: '46px',
  borderRadius: '0 0 8px 8px',
  padding: '2% 4.5% 2% 4.5%',
}

export const errorText = {
  color: fontsColorError,
}

export const messageLine = {
  height: '66px',
  width: '100%',
  padding: '12px 4% 13px 4.47761%',
}

export const messageArea = {
  resize: 'none',
  width: '100%',
  height: '100%',
  border: 'none',
  fontSize: '14px',
  lineHeight: '1.5',
  color: fonts_color_checkout_active,
  outline: 'none',
  padding: '0',
  overflow: 'auto',
  fontFamily: 'inherit',
  backgroundColor: 'transparent',
}

export const cardInfoLine = {
  width: '100%',
  height: '56px',
}

export const firstThirdBox = {
  width: '33.33333%',
  height: '100%',
  display: 'inline-block',
  float: 'left',
  padding: '11px 1.7025% 12px 4.18017%',
  border: '2px solid transparent',
}

export const secondThirdBox = {
  width: '33.33333%',
  height: '100%',
  display: 'inline-block',
  float: 'left',
  padding: '11px 1.7025% 12px 4.18017%',
  border: '2px solid transparent',
  marginLeft: '-1px',
}

export const thirdThirdBox = {
  width: '33.81724%',
  maxWidth: '210px',
  height: '100%',
  display: 'inline-block',
  float: 'right',
  padding: '11px 1.7025% 12px 4.18017%',
  border: '2px solid transparent',
  marginLeft: '-2px',
}

export const quarterBox = {
  border: '2px solid transparent',
  display: 'inline-block',
  float: 'left',
  height: '100%',
  padding: '11px 1% 12px 4%',
  width: '25%',
}

export const errorBorder = {
  border: `solid 2px ${fontsColorError}`,
}

export const venueLine = {
  width: '100%',
  padding: '11px 4.17910% 11px 3.28358%',
  overflow: 'auto',
  color: fonts_color_checkout_active,
}

export const markerIcon = {
  width: '20px',
  height: '21px',
  fontSize: '23px',
  display: 'inline-block',
  float: 'left',
}

export const calendarIcon = {
  width: '20px',
  height: '21px',
  fontSize: '20px',
  display: 'inline-block',
  padding: 2,
  float: 'left',
}

export const summaryText = {
  display: 'inline-block',
  fontSize: '14px',
  float: 'left',
  padding: '4px 0 0 10px',
  width: '90%',
}

export const inlineText = {
  display: 'inline-block',
  fontSize: '14px',
  padding: '4px 0 0 10px',
}

export const clockIcon = {
  width: '20px',
  height: '21px',
  fontSize: '23px',
  display: 'inline-block',
  float: 'left',
  paddingLeft: '1px',
}

export const usersIcon = {
  width: '24px',
  height: '20px',
  fontSize: '20px',
  display: 'inline-block',
  float: 'left',
}

export const moneyIcon = {
  width: '22px',
  height: '19px',
  fontSize: '18px',
  display: 'inline-block',
  float: 'left',
  padding: '1px 0 0 3px',
}

export const ticketText = {
  display: 'inline-block',
  fontSize: '14px',
  float: 'left',
  padding: '1px 0 0 6px',
  maxWidth: '90%',
}

export const ticketPriceText = {
  display: 'inline-block',
  float: 'right',
  height: '19px',
  fontSize: '14px',
  paddingTop: '1px',
}

export const priceBreakdown = {
  width: '100%',
  padding: '12px 4.17910% 16px 4.47761%',
  color: fonts_color_checkout_active,
  overflow: 'auto',
}

export const breakdownLeft = {
  height: '21px',
  display: 'inline-block',
  float: 'left',
  clear: 'left',
  fontSize: '14px',
  lineHeight: '1.5',
}

export const breakdownRight = {
  height: '21px',
  display: 'inline-block',
  float: 'right',
  clear: 'right',
  fontSize: '14px',
  lineHeight: '1.5',
}

export const lastLeft = {
  height: '29px',
  display: 'inline-block',
  float: 'left',
  clear: 'left',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '1.5',
  paddingTop: '8px',
}

export const lastRight = {
  height: '29px',
  display: 'inline-block',
  float: 'right',
  clear: 'right',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '1.5',
  paddingTop: '8px',
}

export const policySection = {
  width: '100%',
  padding: '16px 4.17910% 5px 4.47761%',
  color: fonts_color_checkout_active,
}

export const policy = {
  width: '100%',
  height: '20px',
  marginBottom: '14px',
}

export const checkbox = {
  float: 'left',
  width: '20px',
  height: '20px',
  opacity: '0.7',
  borderRadius: '3px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: fonts_color_checkout_active,
  marginRight: '3.26784%',
  cursor: 'pointer',
}

export const policyLine = {
  display: 'inline-block',
  float: 'left',
  height: '19px',
  fontSize: '13px',
  paddingTop: '1px',
  cursor: 'pointer',
}

export const bookingPolicy = {
  width: '78%',
  height: 'auto',
}

export const infoIcon = {
  width: '24px',
  height: '24px',
  fontSize: '23px',
  display: 'inline-block',
  float: 'right',
  marginTop: '-2px',
  cursor: 'pointer',
}

export const checkIcon = {
  width: '18px',
  height: '18px',
  fontSize: '18px',
  display: 'block',
}

export const footerText = {
  width: '100%',
  padding: '7px 5.35828% 10px',
  fontFamily: 'inherit',
  fontWeight: '300',
  fontSize: '12px',
  lineHeight: '1.8',
  color: fontsColorPrimary,
  textAlign: 'left',
}

export const recaptcha = {
  transform: 'scale(0.90)',
  WebkitTransform: 'scale(0.90)',
  transformOrigin: '0 0',
  WebkitTransformOrigin: '0 0',
  borderStyle: 'solid',
  borderColor: fonts_color_checkout_active,
  height: '82px',
  width: '308px',
}

export const invalidReCaptcha = {
  transform: 'scale(0.90)',
  WebkitTransform: 'scale(0.90)',
  transformOrigin: '0 0',
  WebkitTransformOrigin: '0 0',
  borderStyle: 'solid',
  borderColor: 'red',
  height: '82px',
  width: '308px',
}

export const invalidPhoneNumber = {
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'rgb(243, 55, 107)',
  borderCollapse: 'separate',
}

export const buttonWrapper = {
  paddingBottom: '40px',
}

export const submitButton = {
  width: '92.26666%',
  height: '50px',
  borderRadius: '4px',
  backgroundColor: color_primary,
  textAlign: 'center',
  margin: '0 4%',
  padding: '15px 0',
  fontFamily: 'inherit',
  fontWeight: '400',
  fontSize: '14px',
  letterSpacing: '1px',
  color: fonts_color_button,
  cursor: 'pointer',
}

export const link = {
  textDecoration: 'none',
  display: 'inline-block',
  color: fonts_color_links,
  cursor: 'pointer',
}

export const policyCover = {
  position: 'fixed',
  top: '0',
  left: '0',
  right: '0',
  background: 'rgba(0, 0, 0, 0.5)',
  bottom: '0',
  zIndex: '25',
  margin: '0 auto',
  maxWidth: '700px',
}

export const policyWrapper = {
  height: '470px',
  position: 'fixed',
  top: '77px',
  width: '93.86666%',
  maxWidth: '657.06666px',
  minWidth: '281.59998px',
  zIndex: '50',
  backgroundColor: rgbaBackground,
  transform: 'translate(3.3%, 0)',
  msTransform: 'translate(3.3%, 0)',
}

export const policyHeader = {
  width: '100%',
  height: '50px',
  backgroundColor: color_primary,
  padding: '15px 4.26666% 13px 6.66666%',
}

export const headerText = {
  display: 'inline-block',
  float: 'left',
  height: '22px',
  fontSize: '16px',
  color: 'white',
}

export const headerX = {
  display: 'inline-block',
  width: '18px',
  height: '18px',
  float: 'right',
  marginTop: '-9px',
  cursor: 'pointer',
}

export const policyBody = {
  padding: '19px 7.10227% 2px 7.10227%',
  overflow: 'auto',
  fontSize: '14px',
  color: fontsColorSecondary,
  height: '420px',
}

export const spinnerCover = {
  position: 'fixed',
  top: '0',
  left: '-1px',
  right: '0',
  bottom: '0',
  background: 'rgba(26, 25, 25, 0.901961)',
  zIndex: '101',
  maxWidth: '700px',
  margin: '0 auto',
}

export const spinnerWrapper = {
  position: 'absolute',
  left: '50%',
  top: '35%',
}

export const spinnerBody = {
  position: 'relative',
  left: '-50%',
  backgroundColor: 'white',
  borderRadius: '6px',
  padding: '14px',
}

export const spinnerText = {
  fontFamily: 'inherit',
  fontSize: '20px',
  display: 'block',
  width: '81%',
  margin: '0 auto',
  paddingBottom: '14px',
  textAlign: 'center',
  color: '#333333',
}

export const spinnerTextFailure = {
  fontFamily: 'inherit',
  fontSize: '16px',
  display: 'block',
  width: '81%',
  margin: '0 auto',
  paddingBottom: '14px',
  textAlign: 'center',
  color: fontsColorError,
}

export const spinnerGif = {
  display: 'block',
  margin: '0 auto',
  height: '60px',
}

export const okButton = {
  display: 'block',
  fontSize: '18px',
  color: 'white',
  backgroundColor: 'rgb(208, 26, 33)',
  margin: '0px auto',
  width: '50%',
  height: '30px',
  lineHeight: '30px',
  padding: '0 0px',
  fontFamily: 'inherit',
  textAlign: 'center',
  cursor: 'pointer',
}

export const requestIcon = {
  width: '20px',
  height: '21px',
  fontSize: '17px',
  display: 'inline-block',
  float: 'left',
  padding: '3px 0 0 0',
}

export const partySizeIcon = {
  fontSize: '18px',
  display: 'inline-block',
}

export const timeIcon = {
  fontSize: '18px',
  display: 'inline-block',
  float: 'left',
  padding: '1px 0 0 0',
}

export const partySizePadding = {
  padding: '0 0 0 10px',
}

export const firstNameInput = {
  padding: '0px 2% 0px 8.35392%',
  borderRadius: '8px 0 0 0',
  overflow: 'auto',
}

export const lastNameInput = {
  padding: '0px 2% 0px 8.35392%',
  borderRadius: '0 8px 0 0',
  overflow: 'auto',
}

export const partySizeInput = {
  padding: '0px 2% 0px 5.35392%',
  overflow: 'auto',
}

export const partySizeSingleInput = {
  padding: '0px 2% 0px 2.68358%',
  overflow: 'auto',
}

export const singleFieldInput = {
  padding: '0 3.69880% 0 4.17910%',
}

export const bottomBorderRadius = {
  borderRadius: '0 0 8px 8px',
}

export const thirdBox = {
  paddingLeft: '12.532%',
  overflow: 'auto',
}

export const topBorderRadius = {
  borderRadius: '8px 8px 0 0',
}

export const smallFormThreeBox = {
  width: '33%',
}

export const smallFormFourBox = {
  width: '25%',
}

export const summaryLine = {
  width: '100%',
  padding: '11px 4.17910% 11px 3.28358%',
  overflow: 'auto',
}

export const partySizeBox = {
  padding: '0px 4.17910% 0px 3.28358%',
  overflow: 'auto',
}

export const dateBox = {
  padding: '0px 0px 0px 3.28358%',
}

export const timeBox = {
  display: 'inline-block',
  padding: '0px 4.17910% 0px 10px',
  position: 'absolute',
}

export const hiddenAlert = {
  position: 'absolute',
  top: '-9999px',
  left: '-9999px',
}

export const promoCodeLine = {
  width: '100%',
  padding: '0 4.17910% 0 3.28358%',
}

export const promoCodeField = {
  paddingLeft: '38px',
  height: '45px',
  display: 'inline-block',
  maxWidth: '73%',
}

export const tagDescriptionText = {
  lineHeight: '23px',
  padding: '0 0 0 4px',
  width: '65%',
}

export const tagIcon = {
  fontSize: '21px',
  display: 'inline-block',
  float: 'left',
}

export const addButton = {
  display: 'inline-block',
  float: 'right',
  fontSize: '14px',
  padding: '3px 7px',
  height: '25px',
  marginTop: '-2px',
  cursor: 'pointer',
}

export const selectedTagDisplay = {
  width: '78%',
  marginLeft: '30px',
  float: 'left',
  clear: 'left',
  fontSize: '11px',
}

export const labelElement = {
  fontSize: '11px',
  fontStyle: 'italic',
  marginBottom: '2px',
}

export const miniTag = {
  fontSize: '11px',
  height: '17px',
  lineHeight: '15px',
  padding: '0 4px',
  margin: '0px 5px 5px 0px',
  display: 'inline-block',
  border: '1px solid transparent',
  borderRadius: '8.5px',
  color: 'white',
}

export const titleDropDownLabel = {
  fontSize: '10px',
  color: '#9a9a9a',
  padding: '0px 3.6988% 0px calc(4.1791% - 10px)',
}
