/* global widgetInit */
import { hexToRgb } from '../../utils/color'

const {
  fonts_color_primary,
  color_cell_background,
  color_cell_background_opacity,
  fonts_color_secondary,
  color_lines,
  color_lines_opacity,
  color_primary,
  color_widget_background,
  color_widget_background_opacity,
} = widgetInit.settings
const rgbaCellBackground = hexToRgb(color_cell_background, color_cell_background_opacity)
const rgbaLineBackground = hexToRgb(color_lines, color_lines_opacity)
const rgbaPrimary = hexToRgb(color_primary, 0.2)
const rgbaWidgetBackground = hexToRgb(color_widget_background, color_widget_background_opacity)

export const listWrapper = {
  fontFamily: 'inherit',
}

export const vertTextWrapper = {
  display: 'table-cell',
  height: '100%',
  width: '5.33333%',
  verticalAlign: 'middle',
}

export const vertText = {
  display: 'block',
  WebkitTransform: 'rotate(-90deg)',
  msTransform: 'rotate(-90deg)',
  transform: 'rotate(-90deg)',
  opacity: '0.5',
  fontSize: '11px',
  fontWeight: '800',
  letterSpacing: '2px',
  textAlign: 'center',
  margin: '0 -100%',
  color: fonts_color_secondary,
}

export const itemWrapper = {
  display: 'table',
  tableLayout: 'fixed',
  height: '70px',
  width: '100%',
  backgroundColor: rgbaCellBackground,
  outline: 'none',
  cursor: 'pointer',
}

export const imageWrapper = {
  display: 'table-cell',
  width: '18.4%',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  verticalAlign: 'middle',
}

export const image = {
  position: 'absolute',
  top: '-9999px',
  bottom: '-9999px',
  left: '-9999px',
  right: '-9999px',
  margin: 'auto',
  minWidth: '100%',
  minHeight: '100%',
  width: '130px',
}

export const textWrapper = {
  display: 'table-cell',
  verticalAlign: 'middle',
  width: '76.26666%',
  height: '100%',
  padding: '5px 2.88668%',
  lineHeight: '20px',
}

export const dateItem = {
  display: 'block',
  fontWeight: '300',
  fontSize: '12px',
  color: fonts_color_secondary,
}

export const textItem = {
  display: 'block',
  fontSize: '18px',
  fontWeight: '500',
  color: fonts_color_primary,
}

export const monthWrapper = {
  width: '100%',
  height: '30px',
  textAlign: 'center',
  backgroundColor: rgbaWidgetBackground,
}

export const monthText = {
  opacity: '0.9',
  fontFamily: 'inherit',
  fontSize: '12px',
  fontWeight: '500',
  color: fonts_color_primary,
  display: 'block',
  height: '30px',
  lineHeight: '30px',
}

export const lineItemSeperator = {
  border: `solid 1.5px ${rgbaLineBackground}`,
  margin: '0',
}

export const topButton = {
  position: 'fixed',
  width: '140px',
  height: '44px',
  borderRadius: '100px',
  backgroundColor: '#2c2c2c',
  top: '67px',
  textAlign: 'center',
  left: '50%',
  marginLeft: '-70px',
  zIndex: '2',
  fontFamily: 'inherit',
  boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.2)',
  cursor: 'pointer',
}

export const topText = {
  opacity: '0.5',
  fontSize: '11px',
  fontWeight: '800',
  letterSpacing: '1px',
  color: '#ffffff',
  display: 'block',
  height: '44px',
  lineHeight: '44px',
}

export const eventHighlight = {
  height: '70px',
  width: '100%',
  backgroundColor: rgbaPrimary,
  cursor: 'pointer',
  position: 'absolute',
}

export const loadingBackground = {
  height: '99.5vh',
  width: '100%',
  backgroundColor: rgbaWidgetBackground,
}

export const loadingSpinner = {
  height: '70px',
  top: '50%',
  left: '50%',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
}

export const emptyState = {
  margin: 'auto',
  height: '95vh',
  width: '100%',
  display: 'table',
}

export const emptyStateMessage = {
  display: 'table-cell',
  verticalAlign: 'middle',
}

export const emptyStateImg = {
  margin: 'auto',
  display: 'block',
  width: '60%',
  maxWidth: '120px',
  paddingBottom: '20px',
}

export const emptyStateText = {
  display: 'block',
  fontFamily: 'inherit',
  fontWeight: '500',
  fontSize: 'calc(20px + 1vw)',
  textAlign: 'center',
}

export const listFooter = {
  height: '19px',
  backgroundColor: rgbaWidgetBackground,
}

export const infoIcon = {
  fontSize: '13px',
  cursor: 'pointer',
}

export const buttonInfoIcon = {
  backgroundColor: 'inherit',
  border: '0',
  padding: '0 0 0 8px',
}
