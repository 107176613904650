/* global document, parent */
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectLanguageStrings } from 'widget/events/selectors/languageSelectors'
import { scrollBody, topBody, selectEvent, toggleSpecialAttentionMessage } from '../actions/navigation'
import * as styles from '../assets/styles/eventList'
import List from '../components/List'
import PolicyPopover from '../components/PolicyPopover'
import EventCalendar from './EventCalendar'
import { parseBoolean } from 'widget/events/utils/preloadedState'

const IS_IE = /MSIE \d|Trident.*rv:/.test(navigator.userAgent)

class EventList extends Component {
  constructor(props) {
    super(props)
    this.handleScrollDebounced = _.debounce(this.handleScroll.bind(this), 250)
    this.appBody = document.getElementById('bw-wrapper')
  }

  componentDidMount() {
    const { offset } = this.props
    if (IS_IE) {
      window.scroll(window.pageXOffset, offset)
      window.addEventListener('scroll', this.handleScrollDebounced)
    } else {
      this.appBody.scrollTop = offset
      this.appBody.addEventListener('scroll', this.handleScrollDebounced)
    }
    this.eventListBody = document.getElementById('event-list')
  }

  componentDidUpdate() {
    if (this.props.topClick) {
      if (IS_IE) {
        window.scroll(window.pageXOffset, 0)
      } else {
        this.appBody.scrollTop = 0
      }
    }
  }

  componentWillUnmount() {
    if (IS_IE) {
      window.removeEventListener('scroll', this.handleScrollDebounced)
    } else {
      this.appBody.removeEventListener('scroll', this.handleScrollDebounced)
    }
  }

  handleScroll() {
    const offset = IS_IE ? window.pageYOffset : this.appBody.scrollTop
    const bodyHeight = IS_IE ? window.innerHeight : this.appBody.clientHeight
    this.props.scrollBody(offset, bodyHeight, this.eventListBody.clientHeight)
  }

  showCalendar() {
    let count = 0
    if (this.props.hideCalendar) {
      return false
    }
    return _.some(this.props.availability, date => {
      count += _.size(date)
      return count > 7
    })
  }

  eventsEmptyState() {
    let emptyStateUrl = `${this.props.mediaUrl}images/widget/light-empty-state.jpg`
    let theme = _.assign({}, styles.emptyState, { backgroundColor: 'white' })
    let textStyle = _.assign({}, styles.emptyStateText, { color: '#4A4A4A' })
    if (this.props.theme === 'DARK') {
      emptyStateUrl = `${this.props.mediaUrl}images/widget/dark-empty-state.png`
      theme = _.assign({}, styles.emptyState, { backgroundColor: 'black' })
      textStyle = _.assign({}, styles.emptyStateText, { color: 'white' })
    }

    return (
      <div style={theme}>
        <div style={styles.emptyStateMessage}>
          <img style={styles.emptyStateImg} src={emptyStateUrl} />
          <div style={textStyle}>No upcoming events</div>
          <div style={textStyle}>Check back soon!</div>
        </div>
      </div>
    )
  }

  render() {
    const {
      events,
      numDatesDisplayed,
      availability,
      calendarInfo,
      offset,
      selectEventItem,
      queryObj,
      inventory,
      unopenedEvents,
      colorPrimary,
      fontsColorButton,
      colorLines,
      showSpecialAttentionMessage,
      specialAttentionMessageHeader,
      specialAttentionMessageBody,
      displaySpecialAttentionMessage,
      toggleSpecialAttentionMessage,
    } = this.props
    return (
      <div id="sr-app-body" style={{ paddingTop: '62px' }}>
        {displaySpecialAttentionMessage ? (
          <PolicyPopover
            policyHeader={specialAttentionMessageHeader}
            policyDetails={specialAttentionMessageBody}
            togglePopover={toggleSpecialAttentionMessage}
          />
        ) : null}
        {showSpecialAttentionMessage && (
          <div
            style={{
              backgroundColor: colorPrimary,
              fontColor: fontsColorButton,
              fontSize: '13px',
              borderBottomColor: colorLines,
              textAlign: 'center',
            }}
          >
            <div style={{ padding: '16px 0' }}>
              <span>{specialAttentionMessageHeader}</span>
              <button
                style={{
                  color: fontsColorButton,
                  ...styles.infoIcon,
                  ...styles.buttonInfoIcon,
                }}
                onClick={toggleSpecialAttentionMessage}
                className="fa fa-info-circle fa-fw"
                aria-hidden="true"
                aria-label="special attention message"
              />
            </div>
          </div>
        )}
        {this.showCalendar() ? <EventCalendar /> : null}
        {!_.isEmpty(inventory) || !_.isEmpty(unopenedEvents) || !_.isEmpty(availability) ? (
          <div>
            <List
              events={events}
              availability={availability}
              numDatesDisplayed={numDatesDisplayed}
              calendarInfo={calendarInfo}
              offset={offset}
              topBody={this.props.topBody}
              selectEvent={selectEventItem}
              baseUrl={this.props.baseUrl}
              venueToday={this.props.venueToday}
              selectedDate={this.props.selectedDate}
              queryObj={this.props.queryObj}
            />
            <div style={styles.listFooter} />
          </div>
        ) : (
          this.eventsEmptyState()
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const [todayMonth, todayDay, todayYear] = state.venueInfo.venueToday.split('/')
  const venueToday = `20${todayYear}-${todayMonth}-${todayDay}`
  const languageStrings = selectLanguageStrings(state)
  return {
    events: state.entities.events.toJS(),
    availability: state.entities.availability.toJS(),
    inventory: state.entities.inventory.toJS(),
    unopenedEvents: state.entities.unopenedEvents.toJS(),
    calendarInfo: state.calendar.toJS(),
    offset: state.scroll.get('offset'),
    numDatesDisplayed: state.scroll.get('numDatesDisplayed'),
    topClick: state.scroll.get('topClicked'),
    baseUrl: state.widgetSettings.baseUrl,
    isAnimating: state.scroll.get('isAnimating'),
    venueToday,
    hideCalendar: state.widgetSettings.hideCalendar,
    selectedDate: state.calendar.get('selectedDate'),
    queryObj: state.queryObj,
    mediaUrl: state.widgetSettings.mediaUrl,
    theme: state.widgetSettings.widgetTemplateTheme,
    colorPrimary: state.widgetSettings.colorPrimary,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    colorLines: state.widgetSettings.colorLines,
    showSpecialAttentionMessage: parseBoolean(state.widgetSettings.showSpecialAttentionMessage),
    specialAttentionMessageHeader: languageStrings.textEventWidgetSpecialAttentionLabel,
    specialAttentionMessageBody: languageStrings.textEventWidgetSpecialAttentionInfoBody,
    displaySpecialAttentionMessage: state.formFields.get('displaySpecialAttentionMessage'),
  }
}

const mapDispatchToProps = dispatch => ({
  scrollBody: (...args) => {
    dispatch(scrollBody(...args))
  },
  topBody: () => {
    dispatch(topBody())
  },
  selectEventItem: (date, eventId) => {
    dispatch(selectEvent(date, eventId))
  },
  toggleSpecialAttentionMessage: () => {
    dispatch(toggleSpecialAttentionMessage())
  },
})

EventList = connect(mapStateToProps, mapDispatchToProps)(EventList)

export default EventList
