import _ from 'lodash'
import { createSelector } from 'reselect'

const languageStrings = state => state.languages.selectedLanguageStrings
const venueInfo = state => state.venueInfo

const getLanguageStringValue = () => (newValue, originalValue) => !_.isNil(newValue) ? newValue : originalValue

export const selectLanguageStrings = createSelector(
  [languageStrings, venueInfo, getLanguageStringValue],
  (languageStrings, venueInfo, getLanguageStringValue) => ({
    textEventWidgetSpecialAttentionLabel: getLanguageStringValue(
      languageStrings.eventWidgetSpecialAttentionLabel,
      venueInfo.specialAttentionMessageHeader
    ),
    textEventWidgetSpecialAttentionInfoBody: getLanguageStringValue(
      languageStrings.eventWidgetSpecialAttentionInfoBody,
      venueInfo.specialAttentionMessageBody
    ),

    // identical to reservation widget strings
    textAddButtonLabel: getLanguageStringValue(languageStrings.resWidgetCheckoutAddLabel, 'Add'),
    textCancelButtonLabel: getLanguageStringValue(languageStrings.resWidgetCancelButtonLabel, 'Cancel'),
    textDietaryRestrictions: getLanguageStringValue(languageStrings.resWidgetDietaryRestrictionsLabel, 'Dietary Restrictions'),
    textSaveButtonLabel: getLanguageStringValue(languageStrings.resWidgetSaveButtonLabel, 'Save'),
    textSpecialOccasion: getLanguageStringValue(languageStrings.resWidgetSpecialOccasionLabel, 'Special Occasion'),
    textTagLabelYourGuests: getLanguageStringValue(languageStrings.resWidgetTagLabelYourGuests, 'Your Guests'),
    textTagLabelYours: getLanguageStringValue(languageStrings.resWidgetTagLabelYours, 'Yours'),
    policyUsTwilioSmsOptInSubfooter: getLanguageStringValue(
      languageStrings.policyUsTwilioSmsOptInSubfooter,
      '<i>Reply STOP to unsubscribe. Reply HELP for help. Message frequency varies. Message & data rates may apply.</i>'
    ),
    waitlistWidgetPrivacyPolicyLabel: getLanguageStringValue(languageStrings.waitlistWidgetPrivacyPolicyLabel, 'Privacy Policy'),
  })
)
